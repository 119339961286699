import { AxiosResponse, isAxiosError } from 'axios'

import { isAuthenticatedAxiosError } from '@nx/axios-client'
import { NotFoundError } from '@nx/helpers'

export class ApiError extends Error {}

export class AuthError extends Error {}

export function getAPIError(error: unknown) {
  let response: AxiosResponse | undefined

  if (isAxiosError(error) && error.response) {
    response = error.response
  } else if (isAuthenticatedAxiosError(error)) {
    response = error.error.response as AxiosResponse
  } else if (error instanceof Error) {
    return new ApiError(error.message)
  }

  if (response) {
    const status = response.status
    const { baseURL, url, params } = response.config
    const finalUrl = new URL(url || '', baseURL)
    if (params) {
      finalUrl.search = new URLSearchParams(params).toString()
    }

    if (status === 403) {
      return new AuthError(
        `${status.toString()} ${response.statusText} - ${finalUrl}`
      )
    }

    if (status === 404) {
      return new NotFoundError(
        `${status.toString()} ${response.statusText} - ${finalUrl.toString()}`
      )
    }

    return new ApiError(status.toString())
  }

  return new ApiError('Unknown error')
}
