import { differenceInDays, formatDistanceStrict } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { isPast } from 'date-fns/isPast'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { forwardRef, memo, useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'

import { useGetLaunchDarklyFlags } from '@nx/external/launch-darkly-client'
import { showRegisterButton } from '@nx/fire/auction-hero'
import { FireLink } from '@nx/fire/link'
import { FireTooltip } from '@nx/fire/tooltip'
import {
  AuctionBiddingEngine,
  AuctionType,
  Brand,
  LotStatus,
  MaintenanceMode,
} from '@nx/global-types'
import { buildLotPath } from '@nx/helpers'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'

import { useUserRegistrations } from '@web/helpers/api/user/useUserRegistrations'

import { LotCardBidModal } from '../LotCardBidModal/LotCardBidModal'
import {
  LotCardContainer,
  LotCardMainContainer,
  StyledActionButton,
  StyledAttentionIcon,
  StyledBidNowHint,
  StyledBidNowHintDescription,
  StyledHintContainer,
  StyledLotCardSeparator,
} from './LotCard.styles'
import translate from './LotCard.translate.json'
import { LotCardItemProps } from './LotCard.types'
import { LotCardCompact } from './LotCardCompact'
import LotCardAuctionMobility from './components/LotCardAuctionMobility'
import LotCardImage from './components/LotCardImage'
import { LotCardPrice } from './components/LotCardPrice'
import LotCardStatus from './components/LotCardStatus'
import LotCardTitle from './components/LotCardTitle'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

const LotCardRegisterModalDynamic = dynamic(
  () =>
    import('../LotCardRegisterModal/LotCardRegisterModal').then(
      ({ LotCardRegisterModal }) => LotCardRegisterModal
    ),
  { ssr: false }
)

export const LotCard = memo(
  forwardRef<HTMLDivElement, LotCardProps>(
    (
      {
        variant = 'normal',
        lot,
        isShowingLotId,
        lotClickCallback,
        showAMWidget,
        isFollowButtonDisplayed,
        isBackTopShown,
        isUserPurchase = false,
        showPrice = true,
        showBidNow,
        showRegisterNow,
        userHasAnAddress,
        ...props
      },
      ref
    ) => {
      const router = useRouter()
      const { data: flags } = useGetLaunchDarklyFlags(
        publicRuntimeConfig.LAUNCHDARKLY_CLIENT_KEY
      )
      const showRegisterNowButtonFF = !!flags?.show_register_now_in_lot_card

      const [showBidModal, setShowBidModal] = useState(false)
      const [showRegisterModal, setShowRegisterModal] = useState(false)
      const { userRegistrations } = useUserRegistrations()
      const [showBidNowTooltip, setShowBidNowTooltip] = useState(false)

      let injectAMWidget = false
      let enableRegisterNow = false
      let enableBidNow = false
      let enableBidNowTooltip = false

      if (lot.biddingEngine === AuctionBiddingEngine.AM) {
        if (!showAMWidget) {
          const userRegisterToBid = userRegistrations.some(
            (registration) => registration.iSaleNo === lot.auctionId
          )

          const userApprovedToBid = userRegistrations.some(
            (registration) =>
              registration.iSaleNo === lot.auctionId &&
              registration.approvedToBid
          )
          const isBiddable =
            lot.auctionType === AuctionType.online
              ? lot.biddableFrom
                ? isPast(lot.biddableFrom?.datetime)
                : false
              : !lot.isExhibition

          enableRegisterNow =
            showRegisterNowButtonFF &&
            !!showRegisterNow &&
            !userRegisterToBid &&
            !!userHasAnAddress &&
            showRegisterButton({
              isExhibition: lot.isExhibition,
              auctionType: lot.auctionType,
              auctionStatus: lot.auctionStatus,
              rowId: lot.lotId,
              canBidOnline: isBiddable,
              biddingStatus: lot.biddingStatus,
            })

          if (!enableRegisterNow) {
            const enableBidCheck =
              isBiddable &&
              !showAMWidget &&
              !lot.isPreview &&
              !!lot.lotAMId &&
              lot.isToBeSold &&
              userRegisterToBid

            if (enableBidCheck) {
              if (userApprovedToBid) {
                enableBidNow = !!showBidNow
              } else {
                enableBidNowTooltip =
                  showRegisterNowButtonFF && !!showRegisterNow
              }
            }
          }
        } else {
          injectAMWidget =
            lot.status.toUpperCase() !== LotStatus.withdrawn &&
            !!lot.lotAMId &&
            !showBidModal &&
            publicRuntimeConfig.MAINTENANCE_MODE !== MaintenanceMode.partial
        }
      }

      useEffect(() => {
        if (
          enableRegisterNow &&
          router.query.registerNow &&
          router.query.registerNow === `${lot.lotUniqueId}` &&
          !showRegisterModal
        ) {
          setShowRegisterModal(true)

          const queryParams = Object.assign({}, router.query)
          delete queryParams.registerNow

          void router.replace(
            {
              pathname: router.pathname,
              query: queryParams,
            },
            undefined,
            { scroll: false, shallow: true }
          )
        }
      }, [enableRegisterNow, lot.lotUniqueId, router, showRegisterModal])

      const brandUrl: Record<string, string> = {
        [Brand.cars]: publicRuntimeConfig.CARS_BASE_URL,
      }
      const href = buildLotPath({
        auctionId: lot.auctionId,
        lotId: lot.isPreview ? lot.lotUniqueId : lot.lotId,
        biddingEngine: lot.biddingEngine,
        lotName: lot.title,
        isPreview: lot.isPreview,
        isExhibition: lot.isExhibition,
        domain: brandUrl[lot.brand],
        url: lot.url,
        extraUrlParams: lot.extraUrlParams,
      })

      let nowDate: Date | undefined = undefined
      let auctionEndDate: Date | undefined = undefined

      if (lot.hammerTime?.timezone) {
        nowDate = toZonedTime(new Date(), lot.hammerTime.timezone.iana)
        auctionEndDate = toZonedTime(
          lot.hammerTime.datetime,
          lot.hammerTime.timezone.iana
        )
      }

      return variant === 'compact' ? (
        <LotCardCompact
          image={lot.image}
          lotItemId={lot.lotItemId}
          lotIdNumber={lot.lotIdNumber}
          lotId={lot.lotId}
          biddingEngine={lot.biddingEngine}
          symbols={lot.symbols}
          title={lot.title}
          heading={lot.heading}
          status={lot.status}
          isResultPublished={lot.isResultPublished}
          isExhibition={lot.isExhibition}
          isPreview={lot.isPreview}
          isReferToDepartment={lot.isReferToDepartment}
          isWithoutReserve={lot.isWithoutReserve}
          currencySymbol={lot.currencySymbol}
          currencyISO={lot.currencyISO}
          hammerPrice={lot.hammerPrice}
          hammerPremium={lot.hammerPremium}
          estimateLow={lot.estimateLow}
          estimateHigh={lot.estimateHigh}
          GBPLowEstimate={lot.GBPLowEstimate}
          GBPHighEstimate={lot.GBPHighEstimate}
          auctionType={lot.auctionType}
          isShowingLotId={isShowingLotId}
          lotClickCallback={lotClickCallback}
          isUserPurchase={isUserPurchase}
          href={href}
          showPrice={showPrice}
        />
      ) : (
        <LotCardMainContainer
          ref={ref}
          {...props}
          $isBackTopShown={isBackTopShown}
        >
          <LotCardContainer>
            <LotCardImage
              {...SmartlingAttributes.noTranslate}
              image={lot.image}
              lotItemId={lot.lotItemId}
              auctionId={lot.auctionId}
              title={lot.title}
              href={href}
              lotClickCallback={lotClickCallback}
              isFollowButtonDisplayed={isFollowButtonDisplayed}
              biddingEngine={lot.biddingEngine}
            />
            <div>
              <FireLink href={href} onClick={lotClickCallback}>
                <LotCardTitle
                  lotId={lot.lotId}
                  symbols={lot.symbols}
                  styledDescription={
                    lot.brand === Brand.carsOnline
                      ? text('onlineOnlyTitle', { title: lot.title })
                      : lot.styledDescription
                  }
                  title={lot.title}
                  heading={lot.heading}
                  isShowingLotId={isShowingLotId}
                  biddingEngine={lot.biddingEngine}
                  isExhibition={lot.isExhibition}
                  isPreview={lot.isPreview}
                />
                <LotCardStatus
                  status={lot.status}
                  isAmended={lot.isAmended}
                  isWithoutReserve={lot.isWithoutReserve}
                />
                {showPrice && (
                  <LotCardPrice
                    status={lot.status}
                    auctionType={lot.auctionType}
                    isResultPublished={lot.isResultPublished}
                    isExhibition={lot.isExhibition}
                    isReferToDepartment={lot.isReferToDepartment}
                    currencySymbol={lot.currencySymbol}
                    currencyISO={lot.currencyISO}
                    hammerPrice={lot.hammerPrice}
                    hammerPremium={lot.hammerPremium}
                    estimateLow={lot.estimateLow}
                    estimateHigh={lot.estimateHigh}
                    GBPLowEstimate={lot.GBPLowEstimate}
                    GBPHighEstimate={lot.GBPHighEstimate}
                    highlightWon={isUserPurchase}
                  />
                )}
              </FireLink>

              {injectAMWidget && (
                <LotCardAuctionMobility
                  lotAMId={lot.lotAMId}
                  auctionType={lot.auctionType}
                  lotClosingInString={
                    nowDate &&
                    auctionEndDate &&
                    differenceInDays(auctionEndDate, nowDate) > 0
                      ? text('closingTimer', {
                          numberOfDays: formatDistanceStrict(
                            nowDate,
                            auctionEndDate
                          ),
                        })
                      : undefined
                  }
                  hasHammerPrice={!!lot.hammerPrice}
                />
              )}
            </div>
          </LotCardContainer>
          <StyledLotCardSeparator />

          {enableRegisterNow ? (
            <>
              <StyledActionButton
                variant="ghost"
                onClick={() => setShowRegisterModal(true)}
              >
                {text('registerNow')}
              </StyledActionButton>
              {showRegisterModal && (
                <LotCardRegisterModalDynamic
                  algoliaLotData={lot}
                  setIsOpen={() => setShowRegisterModal(false)}
                />
              )}
            </>
          ) : enableBidNowTooltip ? (
            <StyledHintContainer>
              <StyledBidNowHint>{text('bidNow')}</StyledBidNowHint>
              <FireTooltip
                showTooltip={showBidNowTooltip}
                tooltip={
                  <StyledBidNowHintDescription $level="S">
                    {text('bidNowHint')}
                  </StyledBidNowHintDescription>
                }
                onCloseClick={() => {
                  setShowBidNowTooltip(false)
                }}
                hideCloseButton
              >
                <StyledAttentionIcon
                  onClick={() => setShowBidNowTooltip(true)}
                />
              </FireTooltip>
            </StyledHintContainer>
          ) : (
            enableBidNow && (
              <>
                <StyledActionButton
                  variant="ghost"
                  onClick={() => setShowBidModal(true)}
                >
                  {text('bidNow')}
                </StyledActionButton>
                {showBidModal && (
                  <LotCardBidModal
                    algoliaLotData={lot}
                    lotPageLink={href}
                    setIsOpen={() => setShowBidModal(false)}
                  />
                )}
              </>
            )
          )}
        </LotCardMainContainer>
      )
    }
  ),
  isEqual // deep comparison of props
)

export type LotCardProps = AMWidgetLotCardProps | BidNowLotCardProps

interface CommonLotCardProps {
  variant?: 'normal' | 'compact'
  lot: LotCardItemProps
  lotClickCallback?: () => void
  isShowingLotId: boolean
  isFollowButtonDisplayed?: boolean
  isBackTopShown?: boolean
  isUserPurchase?: boolean
  showPrice?: boolean
}

interface AMWidgetLotCardProps extends CommonLotCardProps {
  showAMWidget: boolean
  showBidNow?: never
  showRegisterNow?: never
  userHasAnAddress?: never
}

interface BidNowLotCardProps extends CommonLotCardProps {
  showAMWidget?: never
  showBidNow: boolean
  showRegisterNow: boolean
  userHasAnAddress: boolean
}
